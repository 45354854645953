import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
//import { RouterProvider } from 'react-router-dom';
//import { router } from './Routes';
import App from './App';

ReactDOM.render(
  <App />,
  // <RouterProvider router={router} />,
  document.getElementById('root')
);
